export default {
	name: 'WarrantsBulletin',
	created () {
		this.$nextTick(function () {
			// 获得数据
			this.GetDiscoverList()
		})
	},
	mounted () {
		this.$nextTick(function () { })
	},
	data () {
		return {
			dataUl: [],
			dataContent: [],
			datain: 0
		}
	},
	computed: {

	},
	watch: {

	},
	methods: {
		// 获取数据
		GetDiscoverList () {
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetDiscoverList',
				data: { lang: localStorage.getItem('lang') || 2 },
				async: false,
				success: (data) => {
					if (data.status == 'SUCCESS') {
						if (data.text.length) {
							this.dataUl = data.text
							setTimeout(function () {
								let H1 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(0).outerHeight()
								let H2 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(1).outerHeight()
								let H3 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(2).outerHeight()
								if ($(window).width() <= 992) {
									$('#WarrantsBulletin>.mainContent>.scope>ul').css({
										'max-height': H1 + H2 + H3 + 30
									})
								} else {
									$('#WarrantsBulletin>.mainContent>.scope>ul').css({
										'max-height': ''
									})
								}
								$(window).resize(function () {
									H1 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(0).outerHeight()
									H2 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(1).outerHeight()
									H3 = $('#WarrantsBulletin>.mainContent>.scope>ul').find('li').eq(2).outerHeight()
									if ($(window).width() <= 992) {
										$('#WarrantsBulletin>.mainContent>.scope>ul').css({
											'max-height': H1 + H2 + H3 + 30
										})
									} else {
										$('#WarrantsBulletin>.mainContent>.scope>ul').css({
											'max-height': ''
										})
									}
								})
							}, 10)
							$.each(this.dataUl, (i, c) => {
								if (c.id == window.location.href.split('=')[1].split('&')[0]) {
									this.GetDiscover(window.location.href.split('=')[1].split('&')[0], window.location.href.split('=')[2], i)
								}
							})

						}
					}
				}
			})
		},
		GetDiscover (id, keyword, index) {
			let url = window.location.href
			history.pushState(null, null, `${url.split('=')[0]}=${id}&keyword=${keyword}`)
			this.datain = index
			$.ajax({
				type: 'POST',
				dataType: 'json',
				url: this.$$.mibb + 'GetDiscover',
				async: false,
				data: { id: id, lang: localStorage.getItem('lang') || 2 },
				success: (data) => {
					if (data.status == 'SUCCESS') {
						this.dataContent = data.text[0]
					}
				}
			})
		}
	}
}
